<template>
  <div class="card text-bg-light" animation="slide" aria-id="contentIdForA11y3" :open="false" style="margin-bottom: 8px">
    <div class="card-header collapsed" data-bs-toggle="collapse" :data-bs-target="'#'+'index'+user.id" aria-expanded="false" style="padding: 8px">
      <span>{{ $t('users_list_params.Login') }}: {{user.login}}</span>
    </div>
    <div class="collapse" :id="'index'+user.id">
      <div class="card-body">
        <div class="content">
          <div class="row">
            <div class="algoritms">
              <table class="table table-bordered table-user-detail">
                <tr>
                  <td>{{ $t('user.first_name') }}:</td>
                  <td>
                    {{ user.first_name }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('user.last_name') }}:</td>
                  <td>
                    {{ user.last_name }}
                  </td>
                </tr>
                <tr v-if="typeof user.roles !== 'undefined'">
                  <td>{{ $t('user.Roles') }}:
                  </td>
                  <td>
                    {{$t('user.'+user.roles)}}
                  </td>
                </tr>
                <tr v-if="user.account.is_active">
                  <td>{{ $t('users_list_params.expired_at') }}:
                  </td>
                  <td>
                    {{ user.account.expired_at | moment('DD.MM.YYYY')}}
                  </td>
                </tr>
                <tr v-else>
                  <td>{{ $t('users_list_params.expired_at') }}:
                  </td>
                  <td><span class="text-danger" style="padding: 0">{{ $t('users_list_params.inactive') }}</span>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('users_list_params.balance') }}:
                  </td>
                  <td>
                    {{ user.account.unlimited ? $t('account.unlimited') : user.account.balance + ' ' + $t('users_list_params.credits')}}
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('users_list_params.limit') }}:
                  </td>
                  <td>
                    {{ user.account.limit }} {{$t('users_list_params.credits')}} / {{user.account.period}} {{ $t('users_list_params.days') }}
                  </td>
                </tr>
                <tr v-if="user.hasOwnProperty('permissions') && user.permissions.length > 0">
                  <td>{{ $t("user.permissions") }}:
                  </td>
                  <td>
                    {{ user.permissions.map(permission => $t('permissions.'+permission)).join(', ') }}
                  </td>
                </tr>
                <tr v-if="typeof user.last_location !== 'undefined' && user.last_location">
                  <td>{{ $t("user.geolocation") }}:</td>
                  <td>
                    {{user.last_location.country}}, {{user.last_location.region}}, {{user.last_location.city}}
                    ({{ parseFloat(user.last_location.latitude).toFixed(4) }}, {{ parseFloat(user.last_location.longitude).toFixed(4) }})
                  </td>
                </tr>
                <tr v-if="user.first_login_at">
                  <td>{{$t('user.first_login')}}:</td>
                  <td>
                    {{moment(user.first_login_at, 'X').format('DD.MM.YYYY, HH:mm')}}
                  </td>
                </tr>
                <tr v-if="user.last_search_at">
                  <td>{{$t('user.last_search')}}:</td>
                  <td>
                    {{moment(user.last_search_at, 'X').format('DD.MM.YYYY, HH:mm')}}
                  </td>
                </tr>
                <tr v-if="user.created_by">
                  <td>{{$t('users_list_params.created_by')}}:</td>
                  <td>
                    {{user.created_by}}
                  </td>
                </tr>
              </table>
              <div>
                <div class="buttonContainer" style="display: flex; margin-top: 8px">
                  <button class="btn btn-primary margin" @click="redirectUser(user.id)" :content="$t('settings.Show')" v-tippy="{ placement : 'top',  arrow: true }"><i class="feather icon-edit" ></i></button>
                  <button class="btn btn-primary margin" @click="showUserDetails(user)" :content="$t('settings.Show')" v-tippy="{ placement : 'top',  arrow: true }"><i class="feather icon-info" ></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import env from "@/configs/env.json";
import moment from "moment";

export default {
  name: "userItemMobile",
  props: ['user'],
  setup() {
    return {
      moment
    }
  },
  data() {
    return {
      deleteModal: false
    }
  },
  methods: {
    deleteUser(user) {
      this.$store.dispatch('users/deleteUser', user).then(()=>{
        this.deleteModal = false
      }).catch((e) => {
        this.deleteModal = false;
        if (e.response.status === 403) {
          this.$toast.error( this.$t('errors.403') , {
            position: "top-center",
            timeout: 50000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false
          });
        }
      })
      this.$emit('updateUsers')
    },
    redirectUser(id) {
      this.$router.push({name: 'UserEdit', params: {id}})
    },
    showUserDetails(user){
      this.$router.push({name: 'UserDetail', params: {id: user.id}})
    },
  },
}
</script>

<style lang="scss" scoped>
.margin{
  margin-left: 4px;
  margin-right: 4px;
}

@media (max-width: 992px) {
  .btn{
    padding-left: 8px;
    padding-right: 8px;
    width: calc(100% - 16px);
  }
  .card-header{
    display: flex;
    justify-content: center;
    h5:after{
      display: none;
    }
  }
}
th {
  white-space: break-spaces;
  vertical-align: middle;
  padding-right: 20px !important;
  text-align: start;
}
.user-wrapper {
  position: relative;
  padding-bottom: 70px;
}
.panel-block b{
  margin-right: 10px;
}
.panel-block {
  color: #363636;
  padding: 0.5em 0.75em;
}

.table-user-detail tr td:first-child{
  width: 160px;
}
.table-user-detail tr td {
  padding-top: 5px;
  padding-bottom: 5px;
  white-space: break-spaces;
  word-break: break-word;
}
.table-user-detail tr td > * {
  padding: 0;
}

@media (max-width: 767px) {
  .table-user-detail tr td:first-child {
    width: auto;
    font-weight: 600;
    border-bottom: 0;
  }
  .table-user-detail tr td:last-child {
    white-space: break-spaces;
    border: 0;
  }
  .table-user-detail tr td {
    display: block;
  }
  .table-user-detail tr {

  }
}
</style>