<template>
  <div>
    <div class=" py-3 px-3">
      <div class="card-header px-0">
        <h5>{{ $t('tenants.organization_list') }}</h5>
      </div>
      <b-table
          style="max-height: calc(100vh - 25rem)"
          sticky-header
          head-variant="light"
          :filter="filter"
          :items="tenantList.data"
          :fields="selectedColumns"
          :empty-text="$t('billing.empty_text')"
          responsive
          show-empty
          small
          :busy="isLoading"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>

        <template #cell(title)="row">
          <div style="max-width: 20vh; white-space: break-spaces">{{ row.value }}</div>
        </template>
        <template #cell(created_by)="row">
          {{ row.value }}
        </template>
        <template #cell(owner.login)="row">
          {{ row.value }}
        </template>
        <template #cell(note)="row">
          <div style="max-width: 20vh; white-space: break-spaces">{{ row.value }}</div>
        </template>
        <template #cell(created_at)="row">
          <div>{{ moment(row.value, 'X').format('DD.MM.YYYY, HH:mm') }}</div>
        </template>
        <template #head(button)="data">
          <div class="text-center">{{ data.label }}</div>
        </template>

        <template #cell()="row">
          <div class="text-sm-center">
            <button class="btn btn-primary"
                    @click="redirectUser(row.item.id)"
                    :content="$t('settings.Edit')"
                    v-tippy="{ placement : 'top',  arrow: true }">
              <i class="feather icon-edit "></i>
            </button>
          </div>
        </template>

      </b-table>
    </div>





  </div>
</template>

<script>
import {mapGetters} from "vuex";
import moment from "moment";

export default {
  label: "list",
  setup() {
    return {
      moment
    }
  },
  data() {
    return {
      filter: null,
      filterOn: [],
      isLoading: false
    }
  },
  methods:{
    redirectUser(id) {
      this.$router.push({name: 'TenantEdit', params: {id}})
    },
  },
  computed:{
    ...mapGetters('tenants', ['tenantList']),
    selectedColumns(){
      return [
        {
          key: 'title',
          label: this.$t('tenants.title'),
          sortable: true
        },
        {
          key: 'created_by',
          label: this.$t('tenants.created_by'),
          sortable: true
        },
        {
          key: 'owner.login',
          label: this.$t('tenants.owner'),
          sortable: true
        },
        {
          key: 'note',
          label: this.$t('tenants.note'),
          sortable: true
        },
        {
          key: 'created_at',
          label: this.$t('tenants.created_at'),
          sortable: true
        },
        {
          key: 'button',
          label: this.$t('tenants.cost'),
          $isDisabled: true,
          sortable: false
        }
      ]
    },
  },
  mounted() {
    this.isLoading= true;
    this.$store.dispatch('tenants/getTenantList').finally(()=>{
      this.isLoading = false;
    })
  }
}
</script>

<style scoped>

</style>